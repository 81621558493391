import React from 'react'

const uitlegTekst = () => {

    const headerStyle = 'text-persona text-2xl pb-2 font-header';

    return (
        <div className="text-base leading-relaxed">

            Deze demo is bedoeld om op een snelle manier een inkijkje te krijgen in de verschillende onderdelen van persona.<br /><br />
            De poster geeft het voorbeeld van hoe een thema in dit geval cyber security vertaald kan worden in een kleine wereld.<br /><br />
            De missie demo geeft een voorbeeld van hoe een verhaal wordt ingeleid en afgerond.<br /><br />
            We hebben hier tussenliggende missies niet ingebouwd.<br /><br />
            De side quest demo geeft een voorbeeld van hoe een speler buiten de hoofdmissies om thema gerelateerde zoekspelletjes speelt waarmee bepaalde onderwerpen extra onder de aandacht gebracht kunnen worden.<br /><br />
            De profiel demo geeft een voorbeeld van hoe binnen het spel, door middel van simpele kennisvragen een kennisprofiel opgebouwd kan worden, in dit voorbeeld is deze al deels ingevuld.<br /><br />
            De demo werkt niet als geïntegreerde spelervaring. Het is wel mogelijk om op te vragen via het email adres.<br /><br />
            <p className="underline">info@personunknown.com</p><br /><br />


            {/* <p className={`${headerStyle}`}>Doel van het spel</p>
            Er is iets aan de hand bij aannemersbedrijf de Weert en het is aan jou om uit te zoeken wat.<br />
            Je valt in  voor een collega om een Inspectie te doen bij de Weert en schrikt meteen van de in het oog springende misstanden.<br />
            Jouw doel is om goed in kaart te brengen van welke misstanden er sprake is. Maar niet alle duistere zaken liggen aan de oppervlakte, er is meer aan de hand.<br /><br />
            Dit komt alleen boven water door mensen aan te spreken en te interviewen. De mate waarin personen  informatie met je delen hangt af van hun vertrouwen in jou.<br />
            Behandel hen correct, eerlijk en accuraat, laat zien dat je weet waar je over praat. Hoe meer vertrouwen en respect je geniet, hoe makkelijker je jouw werk kan uitvoeren.<br /><br />

            <p className={`${headerStyle}`}>Spelonderdelen</p>

            <p className={`${headerStyle}`}>Hoofdmissie</p>
            De hoofdmissie is om een overzicht te krijgen van  alles wat er aan de gang is rondom de onderneming van de heer de Weert.<br />
            Zodra je er klaar voor bent kan je hem confronteren met jouw bevindingen. Wees accuraat!<br /><br />

            <p className={`${headerStyle}`}>Submissies en mijlpalen</p>
            Om de hoofdmissie te volbrengen moet je je dossier compleet maken.<br />
            Er zijn een aantal submissies waarin je een deel van de informatie kan verzamelen rondom de onderneming van de heer de Weert.<br />
            Zodra je denkt dat je genoeg informatie hebt opgehaald uit submissies kan je de confrontatie aangaan met de heer de Weert.<br /><br />

            <p className={`${headerStyle}`}>Sentiment</p>
            Het respect en vertrouwen dat werknemers in jou hebben uit zich in een ‘sentimentscore’;<br />
            hoe beter jij laat zien dat je weet waar je over praat en hoe accurater je bent in het spotten van misstanden, hoe hoger je sentiment zal zijn.<br />
            Maar let op, het omgekeerde is ook van toepassing, je sentiment daalt als je foute meldingen maakt over misstanden of foute antwoorden op kennisvragen geeft.<br />
            Voor sommige submissies is een redelijk of hoog sentiment nodig om de persoon te overtuigen om belangrijke informatie met je te delen.<br /><br />

            <p className={`${headerStyle}`}>Kennisvragen</p>
            Tijdens het spel stellen  werknemers je soms ook wat vragen over hun situatie; mag iets wel of niet, hoe is dit geregeld etc.<br />
            Binnen het spel wordt bijgehouden hoe goed jij deze vragen beantwoord. Een vraag  is gerelateerd aan AMF, ARBO, Opsporing of MHC.<br />
            Aan het einde van het  spel krijg je een profiel te zien met een overzicht van hoe goed jouw kennis was op de vragen.<br />
            Daarnaast heeft het goed of fout beantwoorden van vragen invloed op jouw sentimentscore.<br /><br />

            <p className={`${headerStyle}`}>Misstanden melden</p>

            Bij elke persoon die je aanspreekt heb je de mogelijkheid om misstanden te melden.<br />
            Dit kan zijn omdat er iets mis is met die persoon (bijv. persoonlijke beschermingsmiddelen die missen), zijn directe omgeving (gevaarlijke situatie) of omdat je in
            gesprek met die persoon informatie hebt ontvangen die duidt op een overtreding.<br />
            Meld je misstanden correct dan worden deze afgevinkt op  ‘misstanden overzicht.<br />
            Alle misstanden in dit overzicht zijn te vinden in het spel, het is aan jou om ze bij de juiste persoon te melden.<br />
            Als je een overtreding fout meldt dan heeft dit een negatieve invloed op jouw sentiment.<br /><br />

            <p className={`${headerStyle}`}>Wat moet je doen</p>

            Tijdens het spelen van de missie ontvang je ‘Missie updates’ Hierin is een hint verwerkt die je helpt om de volgende stap in de missie te vinden.<br />
            Een hint kan een beschrijving zijn van een persoon, een locatie of een activiteit die een persoon uitvoert.<br /><br />

            Met behulp van deze hints kan je op de poster zoeken naar de volgende persoon om aan te spreken.<br />
            Het afronden van een gesprek met de juiste persoon eindigt altijd met een nieuwe “Missie update”.<br /><br />

            In het hoofd/ start scherm kan je een overzicht vinden van alle behaalde missie updates en de meest recente missie update.<br />
            Hier kan je dus altijd de laatste hints vinden die je helpen met het vinden van de volgende stap in de missie.<br /><br />

            Als je een “onjuiste” persoon aanspreekt, ofwel een persoon die geen rol heeft in een volgende stap in de missie, heb je wel de mogelijkheid om misstanden te melden rondom deze persoon.<br />
            Bij sommige personen is het ook mogelijk om vragen te stellen.<br />
            Deze “onjuiste” personen hebben ook vaak kennisvragen voor je waarmee je jouw sentiment kunt verhogen.<br /><br />

            <p className={`${headerStyle}`}>Tips voor als je vastloopt</p>

            -	Lees de missie updates goed, hierin zit altijd een hint verwerkt die verwijst naar de volgende persoon om aan te spreken.<br />
            -	Soms kan je niet verder komen in een missie omdat je sentiment te laag is, je kunt jouw  sentimentscore checken op het hoofd/ start scherm.<br />
            -	Je kunt jouw  sentiment verhogen door kennisvragen te vinden en goed te beantwoorden of door misstanden juist te markeren op het misstanden overzicht.<br />
            -	Misstanden kan je markeren door bij een persoon de optie [Meld overtreding] te selecteren.<br />
            -	Misstanden kunnen visueel gesignaleerd worden of door misstanden te herkennen tijdens het stellen van vragen (wanneer mogelijk).<br />
            -	Let op! Meld misstanden alleen als je er zeker van bent dat ze gemaakt zijn! Elke foute melding resulteert in verlaging van je sentiment.<br />
            -	Mocht je tijdens het spel vastlopen omdat je sentiment heel laag is en je geen mogelijkheden meer hebt deze te verbeteren? Mogelijk kun je dan beter opnieuw beginnen of ga de confrontatie aan met de
            heer de Weert (zonder dat je alle informatie uit de submissies hebt gevonden). */}

        </div>
    )
}

export default uitlegTekst