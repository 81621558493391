import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getMissionArray, findIDGivenName, getSelectedSceneFromId } from '../jsonData/DataWrangler';
import { AiOutlineQrcode } from 'react-icons/ai';
import { BsQuestionCircle, BsGraphUp, BsFillBasketFill } from 'react-icons/bs';

import metadata from '../metadata.json';
import screenfull from 'screenfull';
import { getVariableWithName, getVariableWithNamespace } from "../jsonData/DataWrangler";
import Collectables from './Collectables';

const HomeScreen = ({ introConvoBtnVisible, handleScanShortcut, resetCurrentScene }) => {

    const [missions, setMissions] = useState([]);

    const statName = getVariableWithName("GameSettings", "statName1")?.Value;
    const statScore = getVariableWithName("playerproperties", statName);

    const introChatVars = getVariableWithNamespace("CoversationsShortcuts")?.Variables;
    const introChatIDs = [];

    if (introChatVars) {
        for (let i = 0; i < introChatVars.length; i++) {
            const name = findIDGivenName(introChatVars[i].Value, false, false);
            const id = name ? (getSelectedSceneFromId(name).Properties.Id).slice(-4) : null;

            if (id)
                introChatIDs.push(id);
        }
    }

    const [ping, setPing] = useState(true);

    useEffect(() => {
        setMissions(getMissionArray());
        resetCurrentScene();
    }, [])

    function goFullScreen() {
        screenfull.request();
    }

    return (
        <div className="relative">
            <nav className="pt-6 px-5 relative">
                <p className="text-center text-4xl text-persona-white font-header">{`${metadata.title}`}</p>
                <p className="text-center text-xs text-persona-white mb-4 opacity-70">{`${metadata.buildMajor}.${metadata.buildMinor}`}</p>

                <div className="relative">
                    <Link to="/uitleg" className="persona-btn persona-btn-hover mb-3 pl-6 flex">
                        <div className="flex-1 m-auto text-2xl">Speluitleg</div>
                        <BsQuestionCircle className="h-10 w-10 mr-1" />
                    </Link>
                </div>

                <div className="bg-persona-white px-5 pb-3.5 rounded-xl mb-3 pt-3 shadow-xl text-persona-greenLight font-header">
                    <div className="flex flex-row pb-2">
                        <p className="flex-1 text-left text-3xl">Missies</p>
                        {statScore ?
                            <p className="flex-0 text-right text-lg mt-auto">{statName} {statScore.Value}</p> :
                            null
                        }

                    </div>
                    {/* render mission buttons */}
                    {missions.map((mission, key) => {
                        return (
                            <MissionButton mission={mission} key={key} />
                        )
                    })}
                </div>

                {
                    introChatIDs.map((shortcut, key) => {
                        return (
                            <div key={key} className="relative cursor-pointer" onClick={() => { handleScanShortcut(shortcut) }}>
                                <div className="flex flex-row items-center persona-btn persona-btn-hover mb-3 p-2 pl-6">
                                    <div className="flex-1 m-auto text-lg">Open {introChatVars[key].Value}</div>
                                    {ping === true ? <div className="w-4 h-4 rounded-full shadow-lg bg-persona animate-ping mr-6"></div> : null}
                                </div>
                            </div>
                        )
                    })
                }

                {/* {introConvoBtnVisible && introChat ?
                    <div className="relative cursor-pointer" onClick={() => { handleScanShortcut(introChatID) }}>
                        <div className="flex flex-row items-center persona-btn persona-btn-hover mb-3 p-2 pl-6">
                            <div className="flex-1 m-auto text-lg">Open {introChatVar.Value}</div>
                            {ping === true ? <div className="w-4 h-4 rounded-full shadow-lg bg-persona animate-ping mr-6"></div> : null}
                        </div>
                    </div>
                    : null
                } */}

                {/*<div className="relative">
                    <Link to="/collectables" className="persona-btn persona-btn-hover mb-3 pl-6 flex">
                        <div className="flex-1 m-auto text-2xl">Collectables</div>
                    </Link>
                </div>*/}

                <ShortcutButtonRow />

                <button onClick={goFullScreen} className="relative w-full text-persona-white text-opacity-75 mt-5 underline mb-8">
                    {screenfull.isFullscreen === true ? 'Sluit volledig scherm' : 'Volledig scherm'}
                </button>
            </nav>
        </div>
    )
}

const MissionButton = ({ mission }) => {

    const totalSteps = mission.Variables.length;
    const stepsCompleted = mission.Variables.filter(variable => variable.Value.toLowerCase() === "true").length;

    return (
        <div className="relative">
            <Link to={`/mission/${mission.Namespace}`} className="persona-btn-hover relative pl-6 font-header text-persona-white mb-1.5 p-3 rounded-lg shadow-md flex bg-gradient-to-r from-persona to-persona-greenLight">
                <p className="flex-1 tracking-wider">{mission.Description}</p>
                <p className="mr-2">{stepsCompleted} / {totalSteps}</p>
            </Link>
        </div>
    )
}

export default HomeScreen


const ShortcutButtonRow = () => {


    return (
        <div className="flex flex-row text-center space-x-1 shadow-lg text-lg font-bold text-persona">
            <Link to="/scan" className="flex-grow rounded-l-xl py-3 bg-persona-white flex-col transform transition duration-200 hover:scale-105 hover:-translate-y-0.5 hover:shadow-agressive z-0 hover:z-10">
                <AiOutlineQrcode className="h-12 w-12 w-full" />
                <p className="">Scanner</p>
            </Link>

            <Link to="/profile" className="flex-grow py-3 bg-persona-white transform transition duration-200 hover:scale-105 hover:-translate-y-0.5 hover:shadow-agressive z-0 hover:z-10">
                <BsGraphUp className="h-12 w-12 w-full" />
                <p className="">Profiel</p>
            </Link>

            <Link to="/collectables" className="flex-grow rounded-r-xl py-3 bg-persona-white transform transition duration-200 hover:scale-105 hover:-translate-y-0.5 hover:shadow-agressive z-0 hover:z-10">
                <BsFillBasketFill className="h-12 w-12 w-full" />
                <p className="">Collectables</p>
            </Link>
        </div>
    )
}