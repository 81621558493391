import { animated, useTransition, config } from 'react-spring';
import React from 'react'
import { findImageForElement } from '../../jsonData/DataWrangler';

const debugPerson = {
    isPlayer: true,
    scene: {
        Properties: {
            Id: '0x00000000ABC',
            Text: "debugText",
        },
    },
    entity: {
        Properties: {
            DisplayName: "Henkie",
        },
    },
}

// imagePath represents the icon for NPCs
const WhatsappMessage = ({ isPlayer, scene, entity, delay }) => {

    scene = scene ? scene : debugPerson.scene;

    const transitions = useTransition(true, {

        from: {
            opacity: 0,
            transform: `translateX(${isPlayer ? 100 : -100}%) scaleX(0)`,
        },
        enter: {
            opacity: 1,
            transform: `translateX(0%) scaleX(1)`,
        },
        delay: delay,
        config: config.gentle
    })

    if (isPlayer) {
        return transitions(
            (styles, item) => item && <animated.div style={styles} className="flex flex-row justify-end space-x-2 text-persona-white text-sm">
                <div className={`flex flex-col p-2 rounded-2xl rounded-tr-none shadow-lg ml-16 relative mb-5 bg-gradient-to-r from-persona to-persona-greenDark`}>
                    <p className="mx-2 my-1 text-white mr-10">{scene.Properties.Text}</p>
                </div>
            </animated.div>
        )
    }
    else {
        let imagePath = findImageForElement(entity);
        if (imagePath === null)
            imagePath = '';

        return transitions(
            (styles, item) => item && <animated.div style={styles} className="flex flex-row justify-start space-x-2 text-persona-white text-sm">

                <div className="flex-none w-6 h-6 border-2 bg-persona-greenMiddle border-persona rounded-full overflow-hidden shadow-lg">
                    <img src={imagePath} alt="" className="" />
                </div>
                <div className={`flex flex-col p-2 rounded-2xl rounded-tl-none shadow-lg mr-16 relative mb-5 bg-gradient-to-r from-persona-greenMiddle to-persona-greenLight`}>
                    <p className="mx-2 my-1">{scene.Properties.Text}</p>
                </div>
            </animated.div >
        )
    }
}

export default WhatsappMessage;