import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getVariableWithName, getVariableWithNamespace } from "../jsonData/DataWrangler";

import { RiArrowGoBackLine, RiCheckLine } from 'react-icons/ri';

const Collectables = () => {

    const collectablesName = getVariableWithName('GameSettings', 'collectablesName').Value;

    const [collectables, setCollectables] = useState(getVariableWithNamespace('collectables').Variables);

    useEffect(() => {
        //console.log(collectables);
    }, [collectables])

    let history = useHistory();

    return (
        <div className="px-5 pb-8 pt-4 flex flex-col h-screen relative">
            <div className="flex items-center w-full h-24 text-xl">
                <p className="w-full text-3xl text-center font-header text-persona-white tracking-wide capitalize">
                    {collectablesName}
                </p>
            </div>
            <div className="h-full overflow-y-auto mb-5 px-5 py-5 bg-persona-white rounded-2xl shadow-md text-sm space-y-4">
                {collectables.map((collectable, key) => {
                    return (
                        <CollectableItem key={key} collectable={collectable} index={key} />
                    )
                })}
            </div>

            <div className="bg-persona-greenLight rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer" onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>
        </div>
    )
}

export default Collectables


export const CollectableItem = ({ collectable, index }) => {

    return (
        <div className="flex flex-row items-center h-12 rounded-full shadow-md bg-persona-greenLight text-persona-greenDark">
            <p className="flex-grow text-xl ml-4 ">{index + 1} - {collectable.Description}</p>
            <div className="mr-1 h-10 w-10 rounded-full bg-persona-white border-2 border-persona-greenDark">
                {
                    collectable.Value.toLowerCase() === 'true' ?
                        <RiCheckLine className="w-full h-full" /> :
                        null
                }
            </div>
        </div>
    )
}
