import React, { useState } from 'react'
import {
    determineLocationAndContext
} from '../../jsonData/DataWrangler';

const WhatsappHeader = ({ currentNode }) => {
    let nodeId = currentNode.Properties.Id;

    let result = determineLocationAndContext(nodeId);
    let location = "The other person";
    let context = "What it says below his name";
    let imagePath = 'http://placekitten.com/300/300';

    let img = '';

    if (result !== null) {
        location = result.location;
        context = result.context;
        imagePath = result.locationImage;

        if (imagePath)
            img = require('../../' + result.locationImage).default;
    }

    return (

        <div className="flex flex-row px-3 py-2">
            <div className="h-20 mr-4 my-auto">
                <div className="rounded-full w-20 h-full bg-persona border-2 border-persona-white overflow-hidden">
                    <img src={img} alt={imagePath} className="bg-contain" />
                </div>
            </div>
            <div className="flex-col flex-1 my-auto text-persona-white">
                <div className="m-auto text-3xl font-bold font-header">{location}</div>
                <div className="m-auto text-sm">{context}</div>
            </div>
        </div>
    );

}

export default WhatsappHeader;